import React from 'react';
import reviews from '../json/reviews.json';
import { Button, Container } from 'react-bootstrap';
import arrow from '../assets/images/right-arrow-white.svg';
import { Link } from 'gatsby';

const Review = ({
  title,
  author,
  designation,
  img,
  quotesImage,
  order,
}: {
  title: string;
  author: string;
  designation: string;
  img: string;
  quotesImage: string;
  order?: number;
}) => {
  return (
    <div className="py-4 py-lg-5">
      <Container>
        <div className={`col-12 d-md-flex flex-md-row justify-content-md-between align-items-md-center`}>
          <div
            className={`col-6 col-lg-3  ${order ? `float-start me-3 order-${order}` : 'me-3 float-end'}`}
          >
            <img
              src={img}
              className="img-fluid"
              alt="author-image"
            />
          </div>
          <div className="">
            <img
              className="mb-3 quotes-img"
              src={quotesImage}
              alt="quotes-img"
            />
            <div className="fw-500 fnt-sm-14 fnt-20 mb-3">{title}</div>
            <div className="mb-3 fnt-sm-14 fnt-18 ">
              <span className="fw-600">{author}</span>,
              <span className="fw-300 review-designation">{designation}</span>
            </div>
            <Link
              to={`${process.env.GATSBY_APP_URL}/registration/`}
              target="_blank"
            >
              <Button size="lg" className="font-14 fw-500 btn-contact-us">
                Start for free <img src={arrow} alt="arrow" className="ms-2" />
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Review;
