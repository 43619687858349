import React from 'react';
import Footer from '../components/footer';
import Wrapper from '../components/Wrapper';
import Seo from '../shared/seo';
import Acnavbar from '../components/Acnavbar';
import bugsworkfavicon from '../assets/images/favicon.png';
import Hero from '../components/Hero';
import Review from '../components/Review';
import author from '../assets/images/review-author.svg';
import whiteQuotes from '../assets/images/quotes-white.svg';
import quotes from '../assets/images/quotes.svg';

const ReviewPage = () => {
  return (
    <Wrapper>
      <Seo
        title="bugs.work"
        description="Move fast, stay aligned, and build better - together"
      />

      <Acnavbar
        home={true}
        pathname={'review'}
        logo={bugsworkfavicon}
        logoLogic={false}
      />
       <Hero
        isScrollAnimationPresent={false}
        headerText="Your Plan, Your choice"
        subHeading="bugs.work is the top rated visual feedback, bug tracking and no code automation tool for web applications. Our comprehensive solution enables you to create, maintain, and run UI tests that interact with your web properties using real browsers."
      />
      <div className="base-color">
        <Review
          title="Our productivity has significantly improved since we started using this tool. It's intuitive, easy to use, and has streamlined our bug tracking process. The team now spends less time on administrative tasks and more time on actual development. Highly recommend it to any team looking to improve their workflow!"
          author="Wayne Joseph"
          designation="CEO, WUDG"
          img={author}
          quotesImage={quotes}
        />
      </div>

      <div className="capture-section text-white ">
        <Review
          title="Our productivity has significantly improved since we started using this tool. It's intuitive, easy to use, and has streamlined our bug tracking process. The team now spends less time on administrative tasks and more time on actual development. Highly recommend it to any team looking to improve their workflow!"
          author="Wayne Joseph"
          designation="CEO, WUDG"
          img={author}
          quotesImage={whiteQuotes}
          order={1}
        />
      </div>

      <Review
        title="Our productivity has significantly improved since we started using this tool. It's intuitive, easy to use, and has streamlined our bug tracking process. The team now spends less time on administrative tasks and more time on actual development. Highly recommend it to any team looking to improve their workflow!"
        author="Wayne Joseph"
        designation="CEO, WUDG"
        img={author}
        quotesImage={quotes}
      />
      <Footer pathname={'review'} />
    </Wrapper>
  );
};

export default ReviewPage;
